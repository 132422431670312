import React, { useState } from 'react'
import Modal from './Modal'
import Select from './Select'
import validateEmail from '../helpers/validateEmail'
import { addEmailToMailchimp } from '../services/Mailchimp'

const GetTicketsModal = ({ isOpen = false, onClose }) => {

	const handleModalClose = () => {
		onClose()
	}

	/** 
	 * Form
	 */

	const ERROR_EMAIL_FORMAT_INVALID = 'Please enter a valid email.'
	const ERROR_FROM_SERVER = 'Thanks! You’ve already applied!'
	const ERROR_FIELDS_EMPTY = 'Please fill all the fields.'
	const SUCCESS_MESSAGE = 'Thank you for applying!'

	const [email, setEmail] = useState('')
	const [fullName, setFullName] = useState('')
	const [company, setCompany] = useState('')
	const [title, setTitle] = useState('')
	const [city, setCity] = useState('')
	const [interest, setInterest] = useState('')
	const [speaker, setSpeaker] = useState('')

	const [error, setError] = useState(null)
	const [success, setSuccess] = useState(null)

	const interestsDropdownList = [
		'Insights from VCs/investors',
		'Advice from founders',
		'Tech demos',
		'Conversations with political figures',
		'Conversations with cultural figures (musicians, artists, etc.)'
	]

	const handleSubmit = () => {
		setSuccess(null)
		setError(null) 
		if (email === '' || fullName === '' || company === '' || title === '' || city === '' || interest === '' || speaker === '') {
			setError(ERROR_FIELDS_EMPTY)
		}
		else if (validateEmail(email) === null) {
			setError(ERROR_EMAIL_FORMAT_INVALID)
		}
		else {
			addEmailToMailchimp('flagship', email, fullName, company, title, city, interest, speaker)
			.then(res => {
				setSuccess(SUCCESS_MESSAGE)
				setTimeout(() => {
					handleModalClose()
				}, 1000)
			})
			.catch(err => {
				setError(ERROR_FROM_SERVER)
			})
		}
	}

	return (
		<Modal isOpen={isOpen} onClose={handleModalClose}>
			<div className="GetTicketsModal">
				<div className="GetTicketsModal__Left">
					<h6 className="GetTicketsModal__Title">Tech Week Flagship Conference</h6>
					<p className="GetTicketsModal__Text">Apply to attend the flagship conference at the Faena Forum on Miami Beach on Wednesday, April 26.</p>
				</div>
				<div className="GetTicketsModal__Form">
					<svg className="GetTicketsModal__Notch" viewBox="0 0 80 490" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M0 389.141L0.000152588 0H79C79.552 0 79.9991 0.448045 79.998 0.999998L79.002 488.5C79.0009 489.053 78.5526 489.5 78 489.5H76.1808V480.859C76.1808 478.376 75.2567 475.981 73.5883 474.141L2.59258 395.859C0.924161 394.019 0 391.624 0 389.141Z" fill="#F36A2F"/>
					</svg>
					<svg className="GetTicketsModal__NotchMobile" viewBox="0 0 144 42" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M58.7679 -3.70376e-06L143.5 0L143.5 41.2387C143.5 41.383 143.383 41.5 143.239 41.5L0.751844 41.5C0.697035 41.5 0.652617 41.4555 0.652662 41.4007L0.652662 40.8079L2.85989 40.8079C5.2111 40.8079 7.48712 39.9795 9.28818 38.468L52.3396 2.33988C54.1407 0.828463 56.4167 -3.80653e-06 58.7679 -3.70376e-06Z" fill="#F36A2F"/>
					</svg>
					<input type="email" className="GetTicketsModal__Input" placeholder="Your email" onChange={e => setEmail(e.target.value)}/>
					<input type="text" className="GetTicketsModal__Input" placeholder="Full name" onChange={e => setFullName(e.target.value)}/>
					<input type="text" className="GetTicketsModal__Input" placeholder="Company/Affiliation" onChange={e => setCompany(e.target.value)}/>
					<input type="text" className="GetTicketsModal__Input" placeholder="Title" onChange={e => setTitle(e.target.value)}/>
					<input type="text" className="GetTicketsModal__Input" placeholder="Where are you based?" onChange={e => setCity(e.target.value)}/>
					<Select 
						className="GetTicketsModal__Select"
						placeholder="What's your top area of interest?"
						name="interest"
						options={interestsDropdownList}
						selectedOption={interest}
						onSelectOption={newOption => setInterest(newOption)}
						required={true}
					/>
					<input type="text" className="GetTicketsModal__Input" placeholder="Who’d be your ideal mainstage speaker?" onChange={e => setSpeaker(e.target.value)}/>
					<div className="GetTicketsModal__Submit" onClick={handleSubmit}>
						Sign up
						<svg className="GetTicketsModal__SubmitArrow" viewBox="0 0 25 12" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M0 6H23.5M23.5 6L18.5 1M23.5 6L18.5 11" stroke="#EFFCB8"/>
						</svg>
					</div>
					<div className="GetTicketsModal__MessageContainer">
						{success && <p className="GetTicketsModal__Message GetTicketsModal__Message--Success">{success}</p>}
						{error && <p className="GetTicketsModal__Message GetTicketsModal__Message--Error">{error}</p>}
					</div>
				</div>
			</div>
		</Modal>
	)
}

export default GetTicketsModal