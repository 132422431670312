import React, { useState, useEffect } from 'react'
import makeId from '../helpers/makeId'

const Select = ({ className = '', placeholder, name, options, selectedOption = null, onSelectOption, required = false }) => {

	const randomUid = makeId(6)

	const [isOptionsVisible, setIsOptionsVisible] = useState(false)

	const handleOptionSelected = newSelectedOption => {
		// setIsOptionsVisible(false)
		onSelectOption(newSelectedOption)
	}

	useEffect(() => {
		const handleDocumentClick = e => {
			if (!e.target.closest(`.${randomUid}`)) {
				setIsOptionsVisible(false)
			}
		}
		if (isOptionsVisible === true) {
			document.addEventListener('click', handleDocumentClick)
		}
		return () => {
			document.removeEventListener('click', handleDocumentClick)
		}
	}, [isOptionsVisible])

	return (
		<div className={`Select ${randomUid} ${className} ${isOptionsVisible ? 'isOptionsVisible' : ''} ${selectedOption !== null ? 'filled' : ''}`}>
			<div className="Select__Dropdown">
				<div
					className="Select__DropdownHeader"
					onClick={() => setIsOptionsVisible(!isOptionsVisible)}
				>
					<input className="Select__InputHidden" type="text" name={name} defaultValue={selectedOption || ''} required={required} />
					<p className="Select__DropdownSelectedOption">{selectedOption || placeholder}</p>
          <svg className="Select__DropdownArrow" viewBox="0 0 5 4" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.09788 0.5L2.5 3.0566L0.902124 0.5H4.09788Z" fill="#EFFCB8" stroke="#EFFCB8"/>
          </svg>
				</div>
				<ul className="Select__DropdownOptions">
					{options.map((option, index) => {
						if (option !== selectedOption) {
							return (
								<li
									className="Select__DropdownOption"
									onClick={() => handleOptionSelected(option)}
									key={`select-dropdown-option-${option}-${index}`}
								>
									{option}
								</li>
							)
						}
					})}
				</ul>
			</div>
		</div>
	)
}

export default Select