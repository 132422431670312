import axios from 'axios'

/**
*	Add email to mailchimp
*/
const addEmailToMailchimp = (targetAudience, emailAddress, fullName = '', company = '', title = '', city = '', interest = '', speaker = '') => {
	return new Promise((resolve, reject) => {
		axios.post('/.netlify/functions/add-email-to-mailchimp', {
			params: {
				targetAudience: targetAudience,
				emailAddress: emailAddress,
				fullName: fullName,
				company: company,
				title: title,
				city: city,
				interest: interest,
				speaker: speaker
			}
		})
		.then(res => {
			resolve(res)
		})
		.catch(error => {
			// Error here is undefined while it's not in /netlify/functions/add-email-to-mailchimp.js
			reject(error)
		})
	})
}

export { addEmailToMailchimp }