import React, { useState } from 'react'
import { Link, navigate } from 'gatsby'
import smoothScrollTo from '../helpers/smoothScrollTo'
import BurgerBottomImage from '../images/burger-bottom.png'

const Burger = ({ pathname }) => {

	const [isOpen, setIsOpen] = useState(false)

	const handleTriggerClick = () => {
		setIsOpen(!isOpen)
	}

	const handleLinkClick = target => {
		setIsOpen(false)
		if (pathname === '/') {
			const targetEl = document.querySelector(`.Target-${target}`)
			if (targetEl) {
				smoothScrollTo(targetEl)
			}
		} else {
			if (target === 'Tickets') {
				const targetEl = document.querySelector(`.Target-${target}`)
				if (targetEl) {
					smoothScrollTo(targetEl)
				}
			} else {
				navigate('/')
				setTimeout(() => {
					const targetEl = document.querySelector(`.Target-${target}`)
					if (targetEl) {
						smoothScrollTo(targetEl)
					}
				}, 1500)
			}
		}
	}

	return (
		<div className={`Burger ${isOpen ? 'isOpen' : ''}`}>
			<div className="Burger__Trigger" onClick={handleTriggerClick}>
				<div className="Burger__TriggerGlow"></div>
			</div>
			<div className="Burger__Container">
				<div className="Burger__Nav">
					<ul className="Burger__Links">
						<li className="Burger__Link" onClick={() => handleLinkClick('Hero')}>
							Home
						</li>
						<li className="Burger__Link" onClick={() => handleLinkClick('Speakers')}>
							Speakers
						</li>
						<li className="Burger__Link" onClick={() => handleLinkClick('Partners')}>
							Partners
						</li>
						<li className="Burger__Link" onClick={() => setIsOpen(false)}>
							<Link to="/flagship">
								Schedule
							</Link>
						</li>
					</ul>
					<div className="Burger__CtaTickets" onClick={() => handleLinkClick('Tickets')}>
						<svg className="Burger__CtaTicketsArrow" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M-2.11117e-07 3.6731L11.3462 3.67309M11.3462 3.67309L8.16421 0.491113M11.3462 3.67309L8.16421 6.85507" stroke="#928975"/>
						</svg>
						<span className="Burger__CtaTicketsLabel">Tickets</span>
					</div>
				</div>
				<p className="Burger__Dates">
					Apr 23–30<br/>2023
				</p>
				<img
					className="Burger__BurgerImage"
					src={BurgerBottomImage}
				/>
			</div>
		</div>
	)
}

export default Burger