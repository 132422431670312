import { Link, navigate } from 'gatsby'
import React, { useEffect, useState, useRef } from 'react'
import smoothScrollTo from '../helpers/smoothScrollTo'
import useOnScreen from '../hooks/useOnScreen'
import GetTicketsModal from '../components/GetTicketsModal'

const Nav = ({ pathname }) => {

	const containerEl = useRef(null)
	const isOnScreen = useOnScreen(containerEl)
	const [isExpanded, setIsExpanded] = useState(true)

	useEffect(() => {
		if (pathname !== '/') {
			setIsExpanded(false)
		} 
		const limitY = window.innerHeight * 0.125

		const handleScroll = () => {
			const scrollY = window.scrollY
			if (scrollY > limitY && isExpanded === true) {
				setIsExpanded(false)
			}
			else if (scrollY <= limitY && isExpanded === false) {
				setIsExpanded(true)
			}
		}

		window.addEventListener('scroll', handleScroll)
		return () => {
			window.removeEventListener('scroll', handleScroll)
		}
	}, [isExpanded])

	useEffect(() => {
		if (pathname !== '/') {
			setIsExpanded(false)
		} else {
			setIsExpanded(true)
		}
	}, [pathname])

	const handleLinkClick = target => {
		if (pathname === '/') {
			const targetEl = document.querySelector(`.Target-${target}`)
			if (targetEl) {
				smoothScrollTo(targetEl)
			}
		} else {
			if (target === 'Tickets') {
				const targetEl = document.querySelector(`.Target-${target}`)
				if (targetEl) {
					smoothScrollTo(targetEl)
				}
			} else {
				navigate('/')
				setTimeout(() => {
					const targetEl = document.querySelector(`.Target-${target}`)
					if (targetEl) {
						smoothScrollTo(targetEl)
					}
				}, 1500)
			}
		}
	}

	/** 
	 * Modal
	 */
	const [isGetTicketsModalOpen, setIsGetTicketsModalOpen] = useState(false)

	const handleOpenModal = () => {
		setIsGetTicketsModalOpen(true)
	}

	const handleModalClose = () => {
		setIsGetTicketsModalOpen(false)
	}

	return (
		<div className={`Nav ${isExpanded === true ? 'isExpanded' : ''} ${isOnScreen === true ? 'onScreen' : ''}`} ref={containerEl}>
			<div className="Nav__Mask Nav__Mask--Left">
				<div className="Nav__Rect">
					<div className="Nav__RectMask">
						<div className="Nav__RectMasked"></div>
					</div>
				</div>
				<h4 className="Nav__Logo" data-is-link>
					<Link to="/">Tech week</Link>
				</h4>
				<ul className="Nav__Links">
					<li className="Nav__Link" data-is-link onClick={() => handleLinkClick('About')}>
						<span className="Nav__LinkAppear">About</span>
					</li>
					<li className="Nav__Link" data-is-link onClick={() => handleLinkClick('Speakers')}>
						<span className="Nav__LinkAppear">Speakers</span>
					</li>
					<li className="Nav__Link" data-is-link onClick={() => handleLinkClick('Partners')}>
						<span className="Nav__LinkAppear">Partners</span>
					</li>
					<li className="Nav__Link" data-is-link>
						<Link to="/flagship" className="Nav__LinkAppear">
							Schedule
						</Link>
					</li>
				</ul>
				<div className="Nav__Button" data-is-link onClick={() => handleLinkClick('Tickets')}>
					<div className="Nav__ButtonBorder"></div>
					<div className="Nav__ButtonText">
						<span>Get</span>
						<span> </span>
						<span>tickets</span>
					</div>
				</div>
			</div>
			<div className="Nav__Mask Nav__Mask--Right">
				<div className="Nav__Rect">
					<div className="Nav__RectMask">
						<div className="Nav__RectMasked"></div>
					</div>
				</div>
				<h4 className="Nav__Logo" data-is-link>
					<Link to="/"> Tech week </Link>
				</h4>
				<ul className="Nav__Links">
					<li className="Nav__Link" data-is-link onClick={() => handleLinkClick('About')}>
						<span className="Nav__LinkAppear">About</span>
					</li>
					<li className="Nav__Link" data-is-link onClick={() => handleLinkClick('Speakers')}>
						<span className="Nav__LinkAppear">Speakers</span>
					</li>
					<li className="Nav__Link" data-is-link onClick={() => handleLinkClick('Partners')}>
						<span className="Nav__LinkAppear">Partners</span>
					</li>
					<li className="Nav__Link" data-is-link>
						<Link to="/flagship" className="Nav__LinkAppear">
							Schedule
						</Link>
					</li>
				</ul>
				<div className="Nav__Button" data-is-link  onClick={handleOpenModal}>
					<div className="Nav__ButtonBorder"></div>
					<div className="Nav__ButtonText">
						<span>Get</span>
						<span> </span>
						<span>tickets</span>
					</div>
				</div>
			</div>
			<GetTicketsModal isOpen={isGetTicketsModalOpen} onClose={handleModalClose}/>
		</div>
	)
}

export default Nav